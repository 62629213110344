:root {
  --GS10: #11121A;
  --GS20: #191b24;
  --GS60: #8789a3;
  --GS70: #c5c8e0;
  --GS100: #FFFFFF;
  --Primary: #FFE669;
  --PrimaryShadow: #ffe669af;
  --Border: #c0c6f838;
  --BorderLight: #c0c6f823;
  --DarkGlass: #11121aaf;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: /*neue-haas-grotesk-display,*/ Pretendard Variable, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  justify-content: center;
  text-align: center;
  background-color: var(--GS10);
  color: var(--GS70);
}

#root {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

#root > div { /* Wrapper */
  width: 100%;
}

h1, h2, h3, p, ul {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 기본 리스트 스타일 */
li {
  list-style: none;
}

ul {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}


/* 기본 텍스트 스타일 */
p {
  line-height: 150%;
}

h1, h2, h3 {
  color: var(--GS100);
  font-weight: 600;
}

h1, h2 {
  letter-spacing: -0.6px;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 22px;
  letter-spacing: -0.1px;
  color: var(--GS75);
}

a {
  color: var(--GS75);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}