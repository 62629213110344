/* 헤더 스타일 */
header {
    padding: 32px 48px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 99;
    transition: height 0.4s;
}

@media (min-width: 1081px) {
    #headerLinks ul:hover, nav ul:hover {
        transform: translateY(4px);
    }
    .langToggle:hover {
        transform: scale(0.9);
    }
}

@media (max-width: 1080px) {
    header {
        justify-content: center;
    }
    #textR, #textL {
        display: none;
    }
    #headerMenu {
        justify-content: center;
    }
}

@media (max-width: 640px) {
    nav {
        position: fixed;
        bottom: 24px;
    }
    header {
        padding: 24px 48px;
    }
    #headerLinks ul:hover, nav ul:hover {
        transform: translateY(0);
    }
    .langToggle:hover {
        transform: scale(1);
    }
}

/* 좌우 텍스트 애니메이션 */
#textR, #textL {
    font-size: 32px;
    font-weight: 400;
    letter-spacing: -1.5px;
    transition: transform 0.4s ease, opacity 0.4s ease, filter 0.4s ease;
    width: 100%;
}

#textR {
    text-align: right;
}

#textL {
    text-align: left;
}

header.scrolled #textR {
    transform: translateX(100px);
    opacity: 0;
    filter: blur(12px);
}

header.scrolled #textL {
    transform: translateX(-100px);
    opacity: 0;
    filter: blur(12px);
}

/* 버튼 컨테이너 */
#headerMenu {
    display: flex;
    align-items: center;
    gap: 12px;
}

/* URL/네비게이션 공통 스타일 */
#headerLinks ul, nav ul, .langToggle {
    display: flex;
    align-items: center;
    padding: 0 12px;
    transition: color 0.3s ease, transform 0.3s ease, text-shadow 0.3s ease, border 0.3s ease;
    background-color: var(--DarkGlass);
    backdrop-filter: blur(16px);
    height: 52px;
    border: 1px solid var(--Border);
    border-radius: 40px;
}

#headerLinks li:hover, .langToggle:hover {
    color: var(--Primary);
    text-shadow: var(--Primary) 0px 0px 16px;
}

li {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}

/* URL 스타일 */
#headerLinks ul {
    gap: 0px;
}

#headerLinks ul li {
   height: 100%;
   width: 44px;
}

#headerLinks ul li a {
    height: 100%;
    width: 100%;
    line-height: 51px;
 }

/* 네비게이션 스타일 */
nav ul {
    padding: 4px;
}

.nav-link {
    padding: 0px 16px;
    border-radius: 128px;
    line-height: 42px;
    transition: transform 0.2s;
    white-space: nowrap;
}

.nav-link:hover {
    transform: scale(0.95);
}

.nav-link.active {
    background-color: var(--GS70);
    color: var(--GS10);
    font-weight: 600;
    letter-spacing: -0.4px;
}

/* 언어 토글 스타일 */
.langToggle {
    width: 52px;
    color: var(--GS70);
    font-size: 15px;
    cursor: pointer;
    justify-content: center;
}

.langToggle:hover {
    border: 1px solid var(--Primary);
}