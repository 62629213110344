.video-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(12px);
    transition: all 0.3s ease;
}

.video-modal-content {
    position: relative;
    width: 90%;
    max-width: 1280px;
    background: black;
    border-radius: 12px;
    overflow: hidden;
    transform: scale(1);
    opacity: 1;
    transition: all 1.5s cubic-bezier(0.1, 1, 0.1, 1);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.video-modal-backdrop.entering {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
}

.video-modal-backdrop.entering .video-modal-content {
    transform: scale(1.1);
    opacity: 0;
}

.video-modal-backdrop.closing {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
}

.video-modal-backdrop.closing .video-modal-content {
    transform: scale(0.9);
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.3, 1, 0.3, 1);
}

.video-modal-content iframe {
    width: 100%;
    height: 100%;
}