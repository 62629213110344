.listItem {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.listItem:hover {
    text-decoration: none;
}

/* 미디어 컨테이너 스타일 */
.media-container {
    position: relative;
    width: 100%;
    aspect-ratio: 16 / 9;
    border-radius: 16px;
    overflow: hidden;
    background-color: var(--GS20);
    border: 1px solid var(--BorderLight);
}

.media-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.media-wrapper:hover {
    transform: scale(1.04);
}

.media-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
}

.listInfo {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.listMeta {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.subtitle, .date {
    font-size: 15px;
    color: var(--GS60);
}