.List {
    display: grid;
    grid-auto-columns: min-content;
    column-gap: 28px;
    row-gap: 64px;
}

.List img, iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
}

.ListL {
    grid-template-columns: repeat(2, 1fr);
}

.ListM {
    grid-template-columns: repeat(3, 1fr);
}

.ListS {
    grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 1080px) {
    .ListM {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 640px) {
    .ListL, .ListM, .ListS {
        grid-template-columns: 1fr;
    }
} 