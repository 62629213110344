@media (min-width: 641px) {
    section {
        padding: 64px 48px;
    }
    .hrWrapper {
        padding: 0 48px;
    }
    .noBanner {
        height: 80px;
    }
}

@media (max-width: 640px) {
    section {
        padding: 64px 20px;
    }
    .hrWrapper {
        padding: 0 24px;
    }
    .noBanner {
        height: 72px;
    }
}

.maxWidth {
    max-width: 1280px;
}

.maxWidth_small {
    max-width: 720px;
}

.sectionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: start;
    margin: 0 0 32px 0;
}

section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

hr {
    width: 100%;
    border: 0.5px solid var(--BorderLight);
    margin: 4px 0;
}

.hrWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

img, iframe:not(.video-modal-content iframe) {
    border-radius: 12px;
    border: 1px solid var(--BorderLight);
    object-fit: cover;
}

.banner {
    width: 100%;
    border: 0px;
    border-radius: 0;
    height: 320px;
}

.textContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

#headContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

/* Symbol */
#symbol {
    position: relative;
    display: flex;
    justify-content: flex-end;
}
#symbol > div {
    position: absolute;
    width: 128px;
    height: 128px;
    top: -128px;
    transition: 0.3s;
}
#symbol img {
    border: 0px;
    position: absolute;
    width: 100%;
    margin-left: auto;
    animation: bounce 2s ease-in-out infinite alternate;
}
#symbol > div:hover {
    filter: drop-shadow(0 0 32px var(--PrimaryShadow));
    transform: translateY(-12px);
}
#symbol img:hover {
    animation-play-state: paused;
}
@keyframes bounce {
    0% {
        transform: translateY(-8px) scale(1);
        filter: drop-shadow(0 0 16px var(--PrimaryShadow));
    }
    100% {
        transform: translateY(8px) scale(1);
    }
}

/* Profile */
#profileInfo {
    display: flex;
    align-items: center;
    gap: 8px;
}
#profileInfo img {
    width: 28px;
    height: 28px;
    border-radius: 28px;
}


.unHighlight {
    font-weight: 400;
    color: var(--GS60);
}

.titleCenter {
    text-align: center;
    gap: 16px;
}

.historyItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.historyContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}